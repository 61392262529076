import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";
import axios from "axios";


class Servicios extends React.Component {
  state = {
    noticias2: []
  }

  componentDidMount() {
    axios.defaults.headers = {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    };
    axios.get(`./Noticias.json`)
      .then(res => {
        const noticias2 = res.data;
        this.setState({ noticias2 });
        
      })
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative pt-5">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-150">
              <div className="shape shape-style-1  atf2"></div>
              <Container>
                <div className="col ">
                  <Row>
                    <Col lg="12">
                      <h1 className="display-2 text-white text-center mt-5">
                        Blog
                      </h1>
                    </Col>
                  </Row>
                </div>
              </Container>

              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          
          <section className="section section-lg mt-5">
            <Container>
              <Row className="row-grid align-items-center">
              
              {this.state.noticias2.map((noticia, i) => {
                return (
                  <Col lg="12" key={i}>
                    <div className="pr-md-5">
                      <h3 style={{color: "#F35911"}} >
                     
                        <strong>{noticia.title}</strong>
                      </h3>
                      <Badge color="secondary">{noticia.date}</Badge>
                      <p className="text-justify mt-3 h6" style={{color: "#00579E "}} >
                      {noticia.content}
                      </p>
                    </div>
                    <hr style={{background: "#E10916 "}} ></hr>
                  </Col>
                )
  })}
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Servicios;
