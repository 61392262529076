import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import Carousels from "views/IndexSections/Carousel.js";
// reactstrap components

import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Carousel,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import axios from "axios";
// index page sections
import Download from "../IndexSections/Download.js";

class Landing extends React.Component {
  state = {
    items: [],
  };

  componentDidMount() {
    axios.get(`./Noticias.json`).then((res) => {
      const noticias2 = res.data;
      const items = noticias2.slice(0, 3);

      this.setState({ items });
    });
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar  />

        <main ref="main" >
          <div className="position-relative mt-5" >
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1  atf"></div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="12">
                      <h1 className="display-3 text-white">
                        Somos tu solución en las áreas de
                        <span>
                          Auditoría, tributación, finanzas y contabilidad{" "}
                        </span>
                      </h1>
                      <p className="lead text-white">
                        Prestamos servicios de Asesorías Contables, Tributarias,
                        Financieras, y Auditorías Tributarias
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          to="/Contactanos"
                          tag={Link}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-paper-plane-o" />
                          </span>
                          <span className="btn-inner--text">Contacto</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div
                            className="icon icon-shape icon-shape-white rounded-circle mb-4"
                            style={{ backgroundColor: " #00579E" }}
                          >
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6
                            className="text text-uppercase"
                            style={{ color: "#00579E" }}
                          >
                            {" "}
                            Auditoría
                          </h6>
                          <p className="description mt-3">
                            Respondemos a los retos de nuestros clientes con
                            alta calidad y soluciones técnicas de alto valor.
                          </p>
                          {/* <div>
                            <Badge  style={{background: "#00579E", color: "white"}} pill className="mr-1">
                              design
                            </Badge>
                            <Badge  style={{background: "#00579E", color: "white"}} pill className="mr-1">
                              system
                            </Badge>
                            <Badge  style={{background: "#00579E", color: "white"}} pill className="mr-1">
                              creative
                            </Badge>
                          </div>*/}
                          <Button
                            className="mt-4"
                            style={{ backgroundColor: "#00579E", color: "white" }}
                            to="/Servicios/1" tag={Link}
                          >
                            Ver mas
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape rounded-circle mb-4"  style={{ backgroundColor: "#F35911", color: "white" }}>
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className=" text-uppercase"  style={{ color: "#F35911" }}>
                            ASESORÍA TRIBUTARIA y FINANCIERA
                          </h6>
                          <p className="description mt-3">
                            Somos tus asesores para la toma de decisiones, en
                            situaciones que impliquen o generen un riesgo fiscal
                            en el presente o el futuro.
                          </p>
                          <Button
                            className="mt-4"
                            style={{ backgroundColor: "#F35911", color: "white" }}
                            to="/Servicios/2" tag={Link}
                          >
                            Ver mas
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape rounded-circle mb-4" style={{background: "#E10916", color: "white"} }>
                            <i className="fa fa-book" />
                          </div>
                          <h6 className=" text-uppercase" style={{color: "#E10916"} }>
                            Contabilidad
                          </h6>
                          <p className="description mt-3">
                            Procesamos tu información, preparamos oportunamente
                            tus impuestos así como los estados financieros de
                            conformidad con el régimen fiscal que te aplica.
                          </p>
                          <Button
                            className="mt-4"
                            style={{background: "#E10916", color: "white"} }
                            to="/Servicios/4" tag={Link}
                          >
                            Ver mas
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid "
                    src={require("assets/img/promo1.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-satisfied" />
                    </div> */}
                    <h3 style={{color: "#F35911"}}>Quienes somos</h3>
                    <p style={{color: "#00579E "}} >
                      Somos una firma especializada en consultoría fiscal,
                      auditoría y contabilidad que cuenta con un equipo de
                      profesionales calificados y actualizados, cada una con más
                      de 15 años de experiencia atendiendo a empresas nacionales
                      y extranjeras que operan en los diferentes sectores de la
                      economía nacional.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex ">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              style={{
                                backgroundColor: " #E10916",
                                color: "white",
                              }}
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0" style={{color: "#F35911"}}>Integridad</h5>
                            <p className="mt-3" style={{color: "#00579E  "}} >
                              La confianza depositada por nuestros clientes en
                              ATF consultores, S.A., nos motiva para velar por
                              sus intereses respetando estrictamente la ley.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex ">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              style={{
                                backgroundColor: " #E10916",
                                color: "white",
                              }}
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0" style={{color: "#F35911"}}>Experiencia</h5>
                            <p className="mt-3" style={{color: "#00579E  "}}>
                              Contamos con personal con más de 15 años de
                              experiencia en el área fiscal, contable,
                              financiera y auditoría.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex ">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              style={{
                                backgroundColor: " #E10916",
                                color: "white",
                              }}
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0 "style={{color: "#F35911"}}>Confidencialidad</h5>
                            <p className=" mt-3" style={{color: "#00579E  "}}>
                              Toda la información proporcionada por nuestros
                              clientes es resguardada y bajo ningún motivo será
                              compartida a terceros.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* <section className="section section-lg bg-gradient-dark">
            <Container className="pt-sm ">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">
                    Valor Agregado de nuestros servicios
                  </h2>

                  <img
                    alt="..."
                    className="mt-5 img-center img-fluid "
                    src={require("assets/img/valor.png")}
                    style={{ width: "1000px" }}
                  />
                </Col>
              </Row>
            </Container>
       
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section> */}
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3" style={{color: "#F35911"}}>Nuestros Servicios</h2>
                  <p className="lead " style={{color: "#00579E  "}}>
                    En <strong>ATF</strong>, contamos con una variedad de
                    servicios y asesorías destinados y enfocados a dar
                    soluciones adecuadas que permitan el cumplimiento de sus
                    objetivos.
                  </p>
                </Col>
              </Row>

              <Row>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1" style={{color: "#F35911"}} >Auditoría</span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                          style={{background: "#00579E", color: "white"}}
                          to="/Servicios/1" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-2-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1" style={{color: "#F35911"}} >
                          Asesoría Tributaria
                        </span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                           style={{background: "#00579E", color: "white"}}
                          to="/Servicios/2" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-3-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1" style={{color: "#F35911"}} >
                          Asesoría Financiera y Administrativa
                        </span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                           style={{background: "#00579E", color: "white"}}
                          to="/Servicios/3" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-4-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1" style={{color: "#F35911"}} >Contabilidad</span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                           style={{background: "#00579E", color: "white"}}
                          to="/Servicios/4" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-5-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1" style={{color: "#F35911"}} >Gestiones</span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                           style={{background: "#00579E", color: "white"}}
                          to="/Servicios/5" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-6-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1"style={{color: "#F35911"}} >Emprendimiento</span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                           style={{background: "#00579E", color: "white"}}
                          to="/Servicios/6" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
              </Row>
              <Row className="mt-5">
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-7-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1"style={{color: "#F35911"}} >Otros (General)</span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                           style={{background: "#00579E", color: "white"}}
                          to="/Servicios/7" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-8-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1"style={{color: "#F35911"}} >
                          Otros (Contraloría y Guatecompras)
                        </span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                           style={{background: "#00579E", color: "white"}}
                          to="/Servicios/8" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="4" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-9-800x800.jpg")}
                      style={{ width: "200px", height: "180px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1"style={{color: "#F35911"}} >
                          Otros - MINTRAB - MINECO
                        </span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="mt-4"
                           style={{background: "#00579E", color: "white"}}
                          to="/Servicios/9" tag={Link}
                        >
                          Ver mas
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2 className="display-3" style={{color: "#F35911"}} >Blog & Noticias</h2>
                </Col>{" "}
              </Row>
              <Row>
                {this.state.items.map((noticia, i) => {
                  return (
                    <Col className="mb-5 mb-lg-0" lg="4" md="6" key={i}>
                      <Card className="shadow shadow-lg--hover ">
                        <CardBody>
                          <div className="d-flex px-3">
                            <div>
                              <div className="icon icon-shape  rounded-circle text-white"  style={{background: "#E10916 "}}>
                                <i className="fa fa-newspaper-o" />
                              </div>
                            </div>
                            <div className="pl-4">
                              <h5 className="title " style={{color: "#00579E "}}>
                                {noticia.title}
                              </h5>
                              <Badge color="secondary">{noticia.date}</Badge>
                              {/* <a
                          className="text-success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Learn more
                        </a> */}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              <Row className="justify-content-center mt-5">
                <Col className="text-center">
                  <Button
                    className="mt-2"
                     style={{background: "#00579E", color: "white"}}
                    to="/blog"
                    tag={Link}
                  >
                    Ver mas
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
