import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Carousel from "../IndexSections/Carousel.js";
// index page sections

import axios from "axios";


class Servicios extends React.Component {
  state = {
    showMe: 0,
    name: "",
    lastname: "",
    address: "",
    phone: "",
    email: "",
    message: "",
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }


  constructor() {
    super();
    this.class1 = "";
    this.class2 = "";
    this.class3 = "";
    this.class4 = "";
    this.class5 = "";
    this.class6 = "mb-5";

    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async onSubmitForm() {
    this.class1 = "";
    this.class2 = "";
    this.class3 = "";
    this.class4 = "";
    this.class5 = "";
    this.class6 = "mb-5";
    if (
      this.state.name.trim() == "" ||
      this.state.name.trim().length < 4 ||
      /\d/.test(this.state.name.trim())
    ) {
      this.setState({
        showMe: 1,
      });
      this.class1 += " has-danger";
      alert("Nombre no valido");
    } else if (
      this.state.lastname.trim() == "" ||
      this.state.lastname.trim().length < 4 ||
      /\d/.test(this.state.lastname.trim())
    ) {
      this.setState({
        showMe: 1,
      });
      this.class2 += " has-danger";
      alert("Apellido no valido");
    } else if (
      this.state.email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ==
      null
    ) {
      this.setState({
        showMe: 1,
      });
      this.class3 += " has-danger";
      alert("Correo no valido");
    } else if (
      this.state.phone.trim() == "" ||
      this.state.phone.trim().length != 8 ||
      isNaN(this.state.phone.trim())
    ) {
      this.setState({
        showMe: 1,
      });
      this.class4 += " has-danger";
      alert("Telefono no valido");
    } else if (
      this.state.address.trim() == "" ||
      this.state.address.trim().length < 10
    ) {
      this.setState({
        showMe: 1,
      });
      this.class5 += " has-danger";
      alert("Dirección no valida");
    } else if (
      this.state.message.trim() == "" ||
      this.state.message.trim().length < 100
    ) {
      this.setState({
        showMe: 1,
      });
      this.class6 += " has-danger";
      alert("Texto no valido");
    } else {
      try {
        const res = await axios.post(
          //"http://localhost:3001/jobs",
          "https://atfconsultoresgt.com/api/jobs",
          this.state
        );
        if (res.data.success) {
          this.setState({ showMe: 2 });
        }
      } catch (err) {
        this.setState({ showMe: 3 });
      }
    }
  }
  render() {
    const showMe = this.state.showMe;
    let alert;
    if (showMe == 1) {
      alert = (
        <Alert color="danger">
          <span className="alert-inner--icon">
            <i className="fa fa-exclamation-triangle" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>¡Error!</strong> Campos incorrectos.
          </span>
        </Alert>
      );
    } else if (showMe == 2) {
      alert = (
        <Alert color="success">
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Correo enviado</strong> Pronto estaremos en contacto.
          </span>
        </Alert>
      );
    } else if (showMe == 3) {
      alert = (
        <Alert color="danger">
          <span className="alert-inner--icon">
            <i className="fa fa-exclamation-triangle" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>¡Error!</strong> No se pudo enviar el mensaje.
          </span>
        </Alert>
      );
    } else {
      alert = null;
    }
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative pt-5">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-150">
              <div className="shape shape-style-1  atf2"></div>
              <Container>
                <div className="col ">
                  <Row>
                    <Col lg="12">
                      <h1 className="display-2 text-white text-center mt-5">
                        Unete a nuestro equipo
                      </h1>
                    </Col>
                  </Row>
                </div>
              </Container>

              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>

          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col>
                  <Container>
                    <Row className="justify-content-center mt-100">
                      <Col lg="12">
                        {alert}
                        <Card className="bg-gradient-secondary shadow">
                          <CardBody className="p-lg-5">
                            <h4 className="mb-1" style={{ color: "#F35911" }}>
                              ¿Quieres trabajar con nosotros?
                            </h4>
                            <p
                              className="mt-0 h6"
                              style={{ color: "#00579E " }}
                            >
                              Envianos tus datos y te contactaremos
                            </p>
                            <Row className="mt-5">
                              <FormGroup className="col-lg-6 ">
                                {" "}
                                <label style={{ color: "#00579E " }}>
                                  {" "}
                                  Nombre:
                                </label>
                                <InputGroup
                                  className={classnames(
                                    "input-group-alternative ",
                                    this.class1
                                  )}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-user-run" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="Tu nombre debe ser mayor a 4 caracteres"
                                    type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onInputchange}
                                    required
                                  />
                                </InputGroup>
                              </FormGroup>
                              <FormGroup className="col-lg-6">
                                {" "}
                                <label style={{ color: "#00579E " }}>
                                  {" "}
                                  Apellido:
                                </label>
                                <InputGroup
                                  className={classnames(
                                    "input-group-alternative",
                                    this.class2
                                  )}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-user-run" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="Tu apellido debe ser mayor a 4 caracteres"
                                    type="text"
                                    name="lastname"
                                    value={this.state.lastname}
                                    onChange={this.onInputchange}
                                    required
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup
                                className={classnames("col-lg-6", this.class3)}
                              >
                                {" "}
                                <label style={{ color: "#00579E " }}>
                                  {" "}
                                  Correo:
                                </label>
                                <InputGroup
                                  className={classnames(
                                    "input-group-alternative"
                                  )}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-user-run" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="El correo debe tener un formato valido"
                                    type="text"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onInputchange}
                                    required
                                  />
                                </InputGroup>
                              </FormGroup>
                              <FormGroup
                                className={classnames("col-lg-6", {
                                  focused: this.state.nameFocused,
                                })}
                              >
                                {" "}
                                <label style={{ color: "#00579E " }}>
                                  {" "}
                                  Telefono:
                                </label>
                                <InputGroup
                                  className={classnames(
                                    "input-group-alternative",
                                    this.class4
                                  )}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-user-run" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    placeholder="El telefono debe tener 8 digitos"
                                    type="phone"
                                    value={this.state.phone}
                                    onChange={this.onInputchange}
                                    name="phone"
                                    required
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Row>
                            <FormGroup
                              className={classnames("", {
                                focused: this.state.nameFocused,
                              })}
                            >
                              {" "}
                              <label style={{ color: "#00579E " }}>
                                {" "}
                                Dirreción:
                              </label>
                              <InputGroup
                                className={classnames(
                                  "input-group-alternative",
                                  this.class5
                                )}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-user-run" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Tu dirección debe ser mayor a 10 caracteres"
                                  type="text"
                                  name="address"
                                  value={this.state.address}
                                  onChange={this.onInputchange}
                                  required
                                />
                              </InputGroup>
                            </FormGroup>
                            <label style={{ color: "#00579E " }}>
                              {" "}
                              Resumen de tu experiencia:
                            </label>
                            <FormGroup className={this.class6}>
                              <Input
                                className="form-control-alternative"
                                cols="80"
                                name="message"
                                placeholder="El texto debe ser mayor a 100 caracteres"
                                rows="4"
                                type="textarea"
                                value={this.state.message}
                                onChange={this.onInputchange}
                                required
                              />
                            </FormGroup>
                            <div>
                              <Button
                                block
                                className="btn-round"
                                style={{
                                  color: "white ",
                                  backgroundColor: "#E10916",
                                }}
                                size="lg"
                                type="button"
                                onClick={this.onSubmitForm}
                              >
                                Enviar mensaje
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Servicios;
