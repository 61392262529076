/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer has-cards">
          
          <Container>
            <Row className="row-grid align-items-center my-md">
              <Col lg="6">
                <h3 className=" font-weight-light mb-2" style={{color: "#00579E "}}>
                 Contáctanos
                </h3>
                {/* <h4 className="mb-0 font-weight-light">
                No dudes en escribirnos o llamarnos.
                </h4> */}
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6">
                <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href="mailto:info@atfconsultoresgt.com"                  
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-envelope" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                 Escribenos
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.facebook.com/ATF-Consultores-106332248430937/"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Da nos me gusta
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  style={{ backgroundColor: "green", color: "white", border: "none" }}
                  href="https://wa.me/57155113?text=¡Estoy+interesado!"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-whatsapp" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Escribenos
                </UncontrolledTooltip>


                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  style={{  background: "#d6249f",
                    background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",  color: "white", border: "none" }}
                  href="https://instagram.com/atfconsultores?igshid=YmMyMTA2M2Y="                  
                  id="tooltip8298102027"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-instagram" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip8298102027">
                  Siguenos
                </UncontrolledTooltip>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center justify-content-md-between" >
              <Col md="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                  <a style={{color: "#00579E "}}
                    href="https://cutt.ly/portfolio_werner"
                    target="_blank"
                  >
                   Werner Ovalle
                  </a>
                  .
                </div>
              </Col>
              <Col md="6">
                <Nav className="nav-footer justify-content-end">
                  <NavItem>
                    <NavLink
                      href="https://www.linkedin.com/in/werner-ovalle/"
                      target="_blank"
                    >
                      Linkedin
                    </NavLink>
                  </NavItem>
                 
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
