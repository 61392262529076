import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Servicios extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative pt-5">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-150">
              <div className="shape shape-style-1  atf2"></div>
              <Container>
                <div className="col ">
                  <Row>
                    <Col lg="12">
                      <h1 className="display-2 text-white text-center mt-5">
                        Nuestro Equipo
                      </h1>
                    </Col>
                  </Row>
                </div>
              </Container>

              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
        
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid "
                    src={require("assets/img/promo2.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-satisfied" />
                    </div> */}
                    <h1 className="mb-3" style={{color: "#F35911"}}> Nuestro Equipo</h1>
                    <h4 className="text-justify" style={{color: "#00579E "}}>
                      Somos una firma especializada en consultoría fiscal,
                      auditoría y contabilidad que cuenta con un equipo de
                      profesionales calificados y actualizados, cada uno con más
                      de 15 años de experiencia atendiendo a empresas nacionales
                      y extranjeras que operan en los diferentes sectores de la
                      economía nacional.
                    </h4>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Servicios;
