

import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";

import Nosotros from "../../views/examples/Servicios.js";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    return (
      <>
        <header className="header-global"  >
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
           
          >
            <Container>
              <NavbarBrand className="mr-lg-5 " to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/logo.jpeg")}
                  style={{ height: "50px" }}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/logo.jpeg")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav >
                    <DropdownToggle nav to="/" tag={Link}>
                      <i className="ni ni-ui-04 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Inicio</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Servicios</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/Servicios/1" tag={Link}>
                        Auditoría
                      </DropdownItem>
                      <DropdownItem to="/Servicios/2" tag={Link}>
                        Asesoría Tributaria
                      </DropdownItem>
                      <DropdownItem to="/Servicios/3" tag={Link}>
                        Asesoría Financiera y Administrativa
                      </DropdownItem>
                      <DropdownItem to="/Servicios/4" tag={Link}>
                        Contabilidad
                      </DropdownItem>
                      <DropdownItem to="/Servicios/5" tag={Link}>
                        Gestiones
                      </DropdownItem>
                      <DropdownItem to="/Servicios/6" tag={Link}>
                        Emprendimiento
                      </DropdownItem>
                      <DropdownItem to="/Servicios/7" tag={Link}>
                        Otros (General)
                      </DropdownItem>
                      <DropdownItem to="/Servicios/8" tag={Link}>
                        Otros (Contraloría y Guatecompras)
                      </DropdownItem>
                      <DropdownItem to="/Servicios/9" tag={Link}>
                        Otros -MINTRAB -MINECO
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/Equipo" tag={Link}>
                      <i className="fa fa-users d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Nuestro&nbsp;Equipo</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/Blog" tag={Link}>
                      <i className="fa fa-rss d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Blog</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/Documentos" tag={Link}>
                      <i className="fa fa-files-o d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Actualidad</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/Contactanos" tag={Link}>
                      <i className="fa fa-envelope-o d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Contactanos</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/Empleo" tag={Link}>
                      <i className="fa fa-address-card d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Empleo</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                 
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <Button
                  className="btn-icon-only rounded-circle ml-1 mb-1"
                  color="twitter"
                  href="mailto:info@atfconsultoresgt.com" 
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-envelope" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Escribenos
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1 mb-1"
                  color="facebook"
                  href="https://www.facebook.com/ATF-Consultores-106332248430937/"
                  id="tooltip8374404147"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip8374404147">
                  Da nos me gusta
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1 mb-1"
                  style={{ backgroundColor: "green", color: "white", border: "none" }}
                  href="https://wa.me/57155113?text=¡Estoy+interesado!"
                  id="tooltip8298102026"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-whatsapp" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip8298102026">
                  Escribenos
                </UncontrolledTooltip>
                
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
