/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";


import Landing from "views/examples/Landing.js";
import Servicios from "views/examples/Servicios";
import Equipo from "views/examples/Equipo";
import Empleo from "views/examples/Empleo";
import Blog from "views/examples/Blog";
import Documentos from "views/examples/Documentos";
import Contactanos from "views/examples/Contactanos";
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Landing {...props} />} />
      <Route path="/Servicios/:value" exact render={props => <Servicios {...props} />} />
      <Route path="/Equipo" exact render={props => <Equipo {...props} />} />
      <Route path="/Empleo" exact render={props => <Empleo {...props} />} />
      <Route path="/Blog" exact render={props => <Blog {...props} />} />
      <Route path="/Documentos" exact render={props => <Documentos {...props} />} />
      <Route path="/Contactanos" exact render={props => <Contactanos {...props} />} />   
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
