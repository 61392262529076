import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import image1 from "assets/img/theme/team-1-800x800.jpg";
import image2 from "assets/img/theme/team-2-800x800.jpg";
import image3 from "assets/img/theme/team-3-800x800.jpg";
import image4 from "assets/img/theme/team-4-800x800.jpg";
import image5 from "assets/img/theme/team-5-800x800.jpg";
import image6 from "assets/img/theme/team-6-800x800.jpg";
import image7 from "assets/img/theme/team-7-800x800.jpg";
import image8 from "assets/img/theme/team-8-800x800.jpg";
import image9 from "assets/img/theme/team-9-800x800.jpg";

import image10 from "assets/img/theme/team-10-800x800.jpg";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";
import Login from "../IndexSections/Login.js";
class Servicios extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    let title;
    let content;
    let image;

    switch (parseInt(this.props.match.params.value)) {
      case 1:
        title = "Auditoría";
        content = [
          "Auditoría financiera",
          "Auditoría operacional",
          "Auditoría y revisión fiscal para devolución de crédito fiscal de IVA",
          "Auditoría para exención de ISO",
          "Otros …",
        ];
        image = image1;
        break;
      case 2:
        title = "Asesoría Tributaria";
        content = [
          "Diagnóstico fiscal de cumplimiento de obligaciones tributarias ante los distintos órganos de fiscalización (SAT – IGSS – MINTRAB – MINECO, etc.)",
          "Consultoría fiscal (permanente y casos concretos)",
          "Planificación fiscal",
          "Revisión y acompañamiento en la presentación de impuestos",
          "Acompañamiento en atención de auditorías de SAT",
          "Acompañamiento en solicitudes de devolución de crédito fiscal IVA en los diferentes regímenes de solicitud (general, especial,  optativo, electrónico)",
          "Solicitudes de exención de Impuesto de Solidaridad ISO (Casos concretos)",
          "Otros …",
        ];
        image = image2;
        break;
      case 3:
        title = "Asesoría Financiera y Administrativa";
        content = [
          "Por medio de nuestra Asesoría Administrativa y Financiera,  brindamos la oportunidad de tener procesos administrativos y contables ordenados, que generen información financiera útil para la toma de decisiones estratégicas que contribuyan al crecimiento sostenible de los negocios, alcance de metas establecidas, cumpliendo con la regulación fiscal y laboral local.",
          "Otros …",
        ];
        image = image3;
        break;
      case 4:
        title = "Contabilidad";
        content = [
          "Outsourcing y tercerización de servicios ",
          "Gestión contable",
          "Gestión de facturación electrónica",
          "Gestión de nóminas y pagos de salarios",
          "Otros …",
        ];
        image = image4;
        break;
      case 5:
        title = "Gestiones";
        content = [
          "Constitución de empresas ",
          "Patentes de comercio ",
          "Declaraciones Juradas para trámites diversos ",
          "Certificaciones contables ",
          "Inscripción de contribuyentes y empresas (SAT – IGSS – Ministerio de Trabajo)",
          "Activación de usuario de agencia virtual ",
          "Actualización de datos en agencia virtual ",
          "Cambio de regímenes de tributación ",
          "Cambio de contador ",
          "Inscripción de contribuyentes al registro fiscal de importadores ",
          "Inscripción al registro de productores de productos agropecuarios, artesanales y reciclados",
          "Registro de importadores en la Ventanilla Ágil de Importadores ",
          "Otros …",
        ];
        image = image5;
        break;
      case 6:
        title = "Emprendimiento";
        content = [
          "Asesoría personalizada para constituir y/o formalizar un negocio. Nuestra asesoría proporciona distintas alternativas a nivel contable, fiscal, bancario y tecnológico que ayudarán al crecimiento del negocio y que se constituirán en herramientas financieras para una adecuada toma de decisiones.",
          "Otros …",
        ];
        image = image6;
        break;
      case 7:
        title = "Otros (General)";
        content = [
          "Asistencia en implementación de Factura electrónica en línea",
          "Asistencia en implementación de sistemas contables (SAP  - SAP Business One y otros)",
          "Programas de capacitación fiscal y contable",
          "Otros …",
        ];
        image = image7;
        break;
      case 8:
        title = "Otros (Contraloría y Guatecompras)";
        content = [
          "Asistencia en trámites de constancia ante Contraloría General de Cuentas ",
          "Asistencia en trámites varios ante contraloría general de cuentas (habilitación de libros, cajas fiscales, etc. derivados del manejo de fondos públicos) ",
          "Asistencia en el uso del sistema Guatecompras y publicación de bases de cotización y licitación. ",
          "Revisión de cumplimiento administrativo derivado de operaciones relacionadas con la Ley de Contrataciones del Estado y publicaciones en Guatecompras. ",
          "Otros …",
        ];
        image = image8;
        break;
      case 9:
        title = "Otros -MINTRAB -MINECO";
        content = [
          "Cumplimiento de obligaciones administrativas y tributarias ",
          "Habilitación de libro de salarios ",
          "Asistencia en atención de requerimientos y/o audiencias ",
          "Cumplimiento de obligaciones administrativas ante el Departamento de Política Industrial ",
          "Trámites relacionados con operaciones de empresas acogidas al Decreto 29-89 Ley de Fomento y Desarrollo de la Actividad Exportadora y de Maquila y Decreto 65-89 Ley de Zonas Francas. ",
          "Otros …",
        ];
        image = image9;
      default:
        break;
    }
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative pt-5">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-150">
              <div className="shape shape-style-1  atf2"></div>
              <Container>
                <div className="col ">
                  <Row>
                    <Col lg="12">
                      <h1 className="display-2 text-white text-center mt-5">
                        {title}
                      </h1>
                    </Col>
                  </Row>
                </div>
              </Container>

              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>

          <section className="section section-lg ">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img alt="..." className="img-fluid mb-5" src={image} />
                  {title === "Gestiones" && (
                    <img alt="..." className="img-fluid mt-5" src={image10} />
                  )}
                </Col>
                <Col className="order-md-1" md="6">
                  <h3 style={{ color: "#F35911" }}>{title}</h3>

                  <ul className="list-unstyled mt-5">
                    {content.map((item, index) => (
                      <li className="py-2" key={index}>
                        <div className="d-flex ">
                          <div>
                            <Badge
                              className="badge-circle mr-3 "
                              style={{
                                backgroundColor: " #E10916",
                                color: "white",
                                fontSize: "10px",
                                width: "25px",
                                height: "25px",
                              }}
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <h5 className="mb-0" style={{ color: "#00579E" }}>
                              {" "}
                              {item}
                            </h5>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <Login />
        <CardsFooter />
      </>
    );
  }
}

export default Servicios;
