/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";

class Login extends React.Component {
  state = {};
  render() {
    return (
      <>
        <section className="section section-lg section-shaped">
          <div className="shape shape-style-1 " style={{backgroundColor: "#00579E" }}></div>
          <Container className="py-md">
            <Row className="row-grid justify-content-between ">
              <Col lg="12">
                <h3 className="display-3 text-white">
                Beneficios de trabajar con  
                  <span className="text-white">
                  ATF Consultores
                  </span>
                </h3>
                <ul className="list-unstyled mt-5">
                  <li className="py-2">
                    <div className="d-flex ">
                      <div>
                        <Badge
                          className="badge-circle mr-3"
                          style={{
                            backgroundColor: "secondary ",
                            color: "#F35911",
                          }}
                        >
                          <i className="ni ni-check-bold" />
                        </Badge>
                      </div>
                      <div>
                        <p className=" text-white">
                          Equipo multidisciplinario en las distintas áreas de
                          los servicios prestados
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex ">
                      <div>
                        <Badge
                          className="badge-circle mr-3"
                          style={{
                            backgroundColor: "secondary ",
                            color: "#F35911",
                          }}
                        >
                          <i className="ni ni-check-bold" />
                        </Badge>
                      </div>
                      <div>
                        <p className=" text-white">
                          Objetividad y claridad ya que aportamos una visión
                          externa de la empresa
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex ">
                      <div>
                        <Badge
                          className="badge-circle mr-3"
                           style={{
                            backgroundColor: "secondary ",
                            color: "#F35911",
                          }}
                        >
                          <i className="ni ni-check-bold" />
                        </Badge>
                      </div>
                      <div>
                        <p className=" text-white">
                          Aportar una visión global de las mejores prácticas
                          fiscales para la empresa
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex ">
                      <div>
                        <Badge
                          className="badge-circle mr-3"
                           style={{
                            backgroundColor: "secondary ",
                            color: "#F35911",
                          }}
                        >
                          <i className="ni ni-check-bold" />
                        </Badge>
                      </div>
                      <div>
                        <p className=" text-white">
                          Ahorro de tiempo y dinero al delegar ciertas funciones
                          a nuestros profesionales
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex ">
                      <div>
                        <Badge
                          className="badge-circle mr-3"
                           style={{
                            backgroundColor: "secondary ",
                            color: "#F35911",
                          }}
                        >
                          <i className="ni ni-check-bold" />
                        </Badge>
                      </div>
                      <div>
                        <p className=" text-white">
                          Estudio detallado de los problemas fiscales que
                          afectan a su empresa
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex ">
                      <div>
                        <Badge
                          className="badge-circle mr-3"
                           style={{
                            backgroundColor: "secondary ",
                            color: "#F35911",
                          }}
                        >
                          <i className="ni ni-check-bold" />
                        </Badge>
                      </div>
                      <div>
                        <p className=" text-white">
                          Garantizamos la confidencialidad de las consultas e
                          información de cada uno de nuestros clientes
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default Login;
